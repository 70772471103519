
import './App.css';
import { HashRouter  as Router, Routes, Route, useNavigate } from 'react-router-dom';
import Register from './components/Register/Register';
import Home from './components/Home/Home';
import Quiz from './components/Quiz';
import EndScreen from './components/EndScreen';

function App() {
  return (
    <Router>
      <Routes>
        <Route>
          <Route path='/' element={ <Register /> } />
          <Route path='/test' element={ <Quiz /> } />
          <Route path='/testend' element={ <EndScreen /> } />
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
