import React, { useState, useContext } from "react";
import "./Login.css";
import { Link, useNavigate } from "react-router-dom";
import logo from "../../images/crdblogo.png";
import axios from "axios";
import { GameStateContext } from "../../helpers/Contexts";
import Loaders from "../Loaders/Loaders";

const dateToFormat = "1976-04-19T12:59-0500";

let today = new Date();
let date =
    today.getDate() +
    "/" +
    (today.getMonth() + 1) +
    "/" +
    today.getFullYear()
let mydate =
    today.getDate() +
    "/" +
    (today.getMonth() + 1) +
    "/" +
    today.getFullYear() +
    " " +
    today.getHours() +
    ":" +
    today.getMinutes() +
    ":" +
    today.getSeconds();

const url = "https://moran-production.up.railway.app"
// const url = "http://localhost:5001"

export default function Register() {
    const [name, setName] = useState("");
    const [role, setRole] = useState("");
    const [contacts, setContacts] = useState("");
    const [idnumber, setIdnumber] = useState("");
    const [email, setEmail] = useState("");
    const [loading, setLoading] = useState(false);
    let [color, setColor] = useState("#ffffff");
    

    const [status, setStatus] = useState("");
   
    const [notify, setNotfs] = useState(false);
    const [password, setPassword] = useState("")
    const [username, setUsername] = useState("")
    const [phaseshift, setPhaseshift] = useState("")
    const [byster, setB] = useState("Register Account")
    const [permit, setPermit] = useState("")
    const [currentUser, setCurrentUser] = useState(
        JSON.parse(localStorage.getItem("userdata") || null)
    );

    const [conts, setConts] = useState("Register");
    const [cons, setCons] = useState(false)
    const [marks, setMarks] = useState("0");
    const [thec, setC] = useState(true)

    const navigate = useNavigate()

    const { gameState, setGameState, userName, setUserName } = useContext(
        GameStateContext
    );

    const continues = () => {
       
    }

    const registerUser = async () => {


        setB("Register Account")
        setNotfs(false)


        if (name) {
            try {
                setLoading(true)



                let data = {
                  //  id:  Math.floor(Math.random() * 300) + 1,
                    name: String(name),
                    email: String(email),
                    contacts: String(contacts),
                    attempts: 0,
                    points: 0,
                    prize: "none"
                    // Convert createdAt to a string
                };


                const res = await axios.post(`${url}/api/register`, data, {
                    withCredentials: true,
                });
                setNotfs(true)
                setLoading(false);
                navigate("/test")
                

                if (res.data == "User Exists") { 
                    setStatus(res.data);
                    setCons(false)
                } else {
                    setConts("Continue")
                    setCons(true)
                    setC(false)
                    setStatus(res.data);
                    localStorage.setItem("userdata", JSON.stringify(name));
                }

            } catch (err) {
                setNotfs(true)
                setLoading(false);
                setStatus("Registration Failed!");
                setCons(false)

            }
        } else {
            setNotfs(true)
            setStatus("Fill Name!");
            setCons(false)

        }
    };

    const updateData = () => {
        navigate("/signup")
    }
    return (
        <div className="welcome-pages">
            {/* body contents */}
            {loading && <Loaders/>}
            <div className="body-contentcc">
                {/* Highlight words */}


                <div className="ouurr">
                  


                    <div className="sdaconts">
                    <div id="dds" className="sdas">
                        <img src={logo} alt="" className="" />
                    </div>
                        <div className="anaccount" >
                            {thec && <p>REGISTER</p>}
                        </div>

                        {
                            thec && <div className="forms">
                                <div className="input-one">
                                    {/* <i>icon</i> */}
                                    <input placeholder="Name" value={name}
                                        onChange={(e) => setName(e.target.value)} />
                                </div>

                                <div className="input-two">
                                    {/* <i>icon</i> */}
                                    <input placeholder="Email (Optional)" alue={role}
                                        onChange={(e) => setEmail(e.target.value)} />
                                </div>
                                <div className="input-two">
                                    {/* <i>icon</i> */}
                                    <input type="number" placeholder="Phone (Optional)" value={contacts}
                                        onChange={(e) => setContacts(e.target.value)} />
                                </div>



                            </div>
                        }

                        <div className="remember-opt">


                            {thec ? <button className="sign-btn" onClick={registerUser}>
                                {conts}
                            </button> : <button className="sign-btn" onClick={continues}>
                                {conts}
                            </button>}
                        </div>

                        {notify && <div className="stat">
                            <p>{status}</p>
                        </div>}

                        {/* {
                    loading?<div className="spin"> <DotLoader
 
                    color={color}
                    loading={loading}
                    // cssOverride={override}
                    size={25}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                  /></div>:<></>
                } */}
                    </div>


                  
                </div>
                {/* <div className="ssd">
                        <p>@crdbtest2024</p>
                    </div> */}
            </div>
        </div>
    );
}