export const Questions = [
  {
    prompt: "Who is eligible to open Scholar account? ",
    optionA: "Account is for students 18 years and above",
    optionB: "Account is for students 18 years and below",
    optionC: "Account is for students 18 to 45 years",
    optionD: "All the above",
    asnwer: "optionA",
  },
  {
    prompt: "In which currency can the account be opened?",
    optionA: "Scholar Account is currently opened in all currency",
    optionB: "Scholar Account is currently opened in TZS only",
    optionC: "Scholar Account is currently opened in TZS and USD",
    optionD: "Scholar Account is currently opened in TZS, USD and GBP",
    asnwer: "optionB",
  },
  {
    prompt: "What are the minimum operating balances?",
    optionA: "Minimum operating balance is 5,000",
    optionB: "Minimum operating balance is Zero",
    optionC: "Minimum operating balance is Tshs. 20,000",
    optionD: "Minimum operating balance is Tshs. 10,000 – 20,000",
    asnwer: "optionB",
  },

  {
    prompt: "Does Scholar account have monthly maintenance fees?",
    optionA: "No",
    optionB: "Yes",
    optionC: "-",
    optionD: "-",
    asnwer: "optionA",
  },
  
  {
    prompt: "Is the Scholar Account eligible for SimBanking?",
    optionA: "Yes",
    optionB: "No",
    optionC: "-",
    optionD: "-",
    asnwer: "optionA",
  },
  
  {
    prompt: "How can funds be deposited into Scholar Account?",
    optionA: "CRDB Agent, Simbanking, CRDB branches, and incoming transfer from other banks and or MNOs",
    optionB: "CRDB Agent, CRDB branches",
    optionC: "CRDB Agent, Simbanking, CRDB branches",
    optionD: "-",
    asnwer: "optionA",
  },
  
  {
    prompt: "What type of loan can be accessed via Scholar account?",
    optionA: "Scholar advance",
    optionB: "University Advance",
    optionC: "Boom advance",
    optionD: "Pete kichuochuo Advance",
    asnwer: "optionC",
   },
   {
    prompt: "What documents are required to open the Scholar Account?",
    optionA: "University/college or school ID Admission letter or introductory letter from the University/College or School with glued photo and signature ",
    optionB: "University/college or school ID, letter from executive ward",
    optionC: "National ID/Passport/Voters Id",
    optionD: "All the above",
    asnwer: "optionA",
   },
   {
    prompt: "Is the Scholar Account customer eligible for Internet Banking?",
    optionA: "Yes",
    optionB: "No",
    optionC: "-",
    optionD: "-",
    asnwer: "optionA",
   },
   {
    prompt: "Is the Scholar Account customer eligible for a debit Card? ",
    optionA: "Yes",
    optionB: "No",
    optionC: "-",
    optionD: "-",
    asnwer: "optionA",
   },
   {
    prompt: "What services are available on CRDB Simbanking?",
    optionA: "Account balances, transferring funds between accounts, paying bills, buying airtime, and accessing mini statements.",
    optionB: "Loan request and movements payments only",
    optionC: "Cash withdraw ",
    asnwer: "optionA",
   },

   {
    prompt: "What is Simbanking USSD short Code?",
    optionA: "*150*03#",
    optionB: "*150*33#",
    optionC: "*149*03#",
    optionD: "-",
    asnwer: "optionA",
   }
];
