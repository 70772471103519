import React ,{useState} from "react";
import "../App.css";
import { useContext } from "react";
import { GameStateContext } from "../helpers/Contexts";
import { Questions } from "../helpers/Questions";
import logo from "../images/crdblogo.png";
import PDFFile from '../components/PDFFile';
import { PDFDownloadLink } from "@react-pdf/renderer";
import axios from "axios";
import { Award } from 'react-award';
import { useNavigate } from "react-router-dom";
import Loaders from "../components/Loaders/Loaders";

// const url = "http://localhost:5001"
const url = "https://moran-production.up.railway.app"
const EndScreen = () => {
  const { score, setScore, setGameState, userName } = useContext(
    GameStateContext
  );
  const [currentUser, setCurrentUser] = useState(
    JSON.parse(localStorage.getItem("userdata") || null)
  );

  const [cons,setCons] = useState(false);
  const [awad,setAward] = useState(false);
  const [prize,setPrize] = useState("");
  const [flag,setFlag] = useState(false);
  const [ loading, setLoading ] = useState( false );
  const navigate = useNavigate();

  const restartQuiz = () => {
    setScore(0);
    setGameState("menu");
  };
 const restart =()=>{
  navigate("/")
 }
 

  const updateScore = async() => {
    try{
      setLoading( true )
      let data = {

        attempts: score,
        points: Math.ceil(Number(Number(score)/Number(12))*100)
    };

      const res = await axios.put(`${url}/api/user/${currentUser}`, data, {
        withCredentials: true,
    });

    // console.log(res)


    setCons(true);
    setAward(true);
    setLoading( false )

    }catch(err){
setCons(false)
    }
  }
  return   currentUser &&  (
    <div className="welcome-pages">
           { loading && <Loaders /> }
      <div className="ouur">
        <div className="sdas">
          <img src={logo} alt="" className="" />
        </div>

      

        <h2>Quiz Finished</h2>
        <h3>{userName}</h3>

        <p>Scored: {score} / {Questions.length}</p>

        <div className={Math.ceil(Number(Number(score)/Number(12))*100) >= 90 ? 'scores' : 'noscores'}>
           <h1 id={Math.ceil(Number(Number(score)/Number(12))*100) >= 90 ? 'scores' : 'noscores'} >
          <p>Average Score: {Math.ceil(Number(Number(score)/Number(12))*100)}%</p>
        </h1>
        </div >

        

        {!cons && <div >
          <button id="restarts" onClick={updateScore}>
              SUBMIT 
          </button>
        </div>}
       
        
 
    
      </div>

    </div>
  );
};

export default EndScreen;
