import "../App.css";
import { Questions } from "../helpers/Questions";
import { useState } from "react";
import logo from "../images/crdblogo.png";
import { useContext } from "react";
import { GameStateContext } from "../helpers/Contexts";
import { Link, useNavigate } from "react-router-dom";

import Loaders from "../components/Loaders/Loaders";

import PDFFile from '../components/PDFFile';
import { PDFDownloadLink } from "@react-pdf/renderer";
import axios from 'axios'

let count = 0;

// const url = "http://localhost:5001"
const url = "https://moran-production.up.railway.app"
function Quiz() {
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [optionChosen, setOptionChosen] = useState("");
  const [dataArray, setDataArray] = useState([]);
  const [score, setScore] = useState(0);
  const [ loading, setLoading ] = useState( false );
  const [currentUser, setCurrentUser] = useState(
    JSON.parse(localStorage.getItem("userdata") || null)
  );

  const {  setGameState, userName } = useContext(
    GameStateContext
  );

  const [selectedOption, setSelectedOption] = useState(null);

  const [cons,setCons] = useState(false);
  const [awad,setAward] = useState(false);
  const [prize,setPrize] = useState("");
  const [flag,setFlag] = useState(false);
  const [flags,setFlags] = useState(true);
  const [thflag,setThflags] = useState(false);
  const navigate = useNavigate();

  const [dgst,setDgst] = useState(false)

  const restartQuiz = () => {
    setScore(0);
 
  };
 const restart =()=>{
  navigate("/")
 }

  const okeyDatas = async ()=>{
   if (count==0){
    try{
      setLoading( true )
      const resp = await axios.get(`https://moran-production.up.railway.app/api/prizes`, {
        withCredentials: true,
    });

    // console.log(resp.data)
    setPrize(resp.data)
    setFlags(false)
    setDgst(true)
    setLoading( false )

    }catch(err){
      console.log(err)
    }
    count++;
   }
  }

  const updateScore = async() => {
    try{
      setCons(true)
      let data = {

        attempts: score,
        points: Math.ceil(Number(Number(score)/Number(12))*100)
    };
    setLoading( true )

    if (Math.ceil(Number(Number(score)/Number(12))*100)>=90){
      setFlag(true)
    }

      const res = await axios.put(`${url}/api/user/${currentUser}`, data, {
        withCredentials: true,
    });


    setCons(true);
    setAward(true);
    setLoading( false )
    }catch(err){
setCons(false)
    }
  }

  const contin = () => {
    setThflags(true)
}

  const handleOptionClick = (option) => {
    setSelectedOption(option);
  };


  const appendData = (newData) => {
    setDataArray([...dataArray, newData]);
    setSelectedOption(newData);
    setOptionChosen(newData);
  };

  const chooseOption = (option) => {
    setOptionChosen(option);
  };

  const nextQuestion = () => {
    setSelectedOption("")
    if (Questions[currentQuestion].asnwer == optionChosen) {
      setScore(score + 1);
    }
    setCurrentQuestion(currentQuestion + 1);
  };



  const finishQuiz = () => {
    if (Questions[currentQuestion].asnwer == optionChosen) {
      setScore(score + 1);
    }
  };

  return (
    <div className="welcome-pages">
     { loading && <Loaders /> }
      <div className="ouur">

      <div className="sdas">
                        <img src={logo} alt="" className="" />
                    </div>


    <div className="sdaconts">
    <h2>{Questions[currentQuestion].prompt}</h2>
    </div>
      
      <div className="sdacontss">
        <button  className={selectedOption === 'optionA' ? 'selected' : ''} 
                  onClick={() => {
            appendData("optionA");
          }}
        >
          {Questions[currentQuestion].optionA}
        </button>
        <button className={selectedOption === 'optionB' ? 'selected' : ''} 
          onClick={() => {
            appendData("optionB");
          }}
        >
          {Questions[currentQuestion].optionB}
        </button>
        <button className={selectedOption === 'optionC' ? 'selected' : ''} 
          onClick={() => {
            appendData("optionC");
          }}
        >
          {Questions[currentQuestion].optionC}
        </button>
        <button className={selectedOption === 'optionD' ? 'selected' : ''} 
          onClick={() => {
            appendData("optionD");
          }}
        >
          {Questions[currentQuestion].optionD}
        </button>
      </div>

      {/* { <div className="stat">
                            <p>asnser</p>
                        </div>} */}

      {currentQuestion == Questions.length - 1 ? (
        <button onClick={finishQuiz} id="nextQuestion" onClickCapture={contin}>
          Finish Quiz
          
        </button>
      ) : (
        <button onClick={nextQuestion} id="nextQuestion">
          Next Question
        </button>
      )}


    </div>

    {
      thflag&&  <div className="stato">
                          <div className="ouur">
        <div className="sdas">
          <img src={logo} alt="" className="" />
        </div>

      

        <h2>Quiz Finished</h2>
        <h3>{userName}</h3>

        <p>Scored: {score} / {Questions.length}</p>

        <div className={Math.ceil(Number(Number(score)/Number(12))*100) >= 90 ? 'scores' : 'noscores'}>
           <h1 id={Math.ceil(Number(Number(score)/Number(12))*100) >= 90 ? 'scores' : 'noscores'} >
          <p>Average Score: {Math.ceil(Number(Number(score)/Number(12))*100)}%</p>
        </h1>
        </div >

        

        {!cons && <div >
          <button id="restarts" onClick={updateScore}>
              SUBMIT 
          </button>
        </div>}
       
        
 
      
      </div>

      {awad && <div className="award">
        <div className="awad">
        <div className="sdas">
          <img src={logo} alt="" className="" />
        </div>
        {flag ?<p>CONGRATULATIONS!</p>:<p>NO PRIZE!</p>}

        {
          flag && <div className="youwon">
            <p> {prize.toLocaleUpperCase()}</p>
          </div>
        }
        
        {flag ? <button id="restart" onClick={okeyDatas}>
          PRIZE!
          </button>:<button id="restart" onClick={restart}>
          RESTART!
          </button>}

        
        </div>

       {dgst&& <div className="ao">
   <PDFDownloadLink document={<PDFFile prize={prize} point={score}/>} filename="Quiz_Results">
        {({loading}) => (loading ? <button id="restart">Loading Doc...</button> : <button  id="restart">Download Results</button> )}
        </PDFDownloadLink>
       
        </div >}
  
     
        </div>}
     
                        </div>

                        
    }



    </div>
  );
}

export default Quiz;
