import React, { useState } from "react";
import { Page, Text, Image, Document, StyleSheet } from "@react-pdf/renderer";
import { Font } from '@react-pdf/renderer';
import ReactPDF from '@react-pdf/renderer';
import logo from '../images/crdblogo.png'
import { GameStateContext } from "../helpers/Contexts";
import { useContext } from "react";
import axios  from "axios";
import Loaders from "../components/Loaders/Loaders";


// const url = "http://localhost:5001"
const url = "https://moran-production.up.railway.app"
const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
    borderStyle:'solid black'
  },
  title: {
    fontSize: 24,
    textAlign: "center",

  },
  text: {
    margin: 12,
    fontSize: 14,
    textAlign: "center",


  },
  image: {
    marginVertical: 15,
    marginHorizontal: 100,
    borderRadius: 20
  },
  header: {
    fontSize: 14,
    marginBottom: 20,
    textAlign: "center",
    color: "grey",

  },
  headers: {
    fontSize: 12,
    textAlign: "center",
    color: "black",

  },
  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "black",

  },
});

const PDFFile = ({point,prize}) => {

  const [ loading, setLoading ] = useState( false );
  const pageColors = ['#fff', '#fff', '#ff'];
  const [currentUser, setCurrentUser] = useState(
    JSON.parse(localStorage.getItem("userdata") || null)
  );
 const [points,setPoints] = useState("");
 
  const pages = [
    { text: 'Thank you for choosing us, its our pleasure to improve our services for better customer experience!', image: logo },
  ]


  // ReactPDF.render(<MyDocument />, `example.pdf`);

  return (
    <Document>
      {pages.map((page, index) => {
        return (
          <Page size="A4" key={index} style={{ ...styles.body, backgroundColor: pageColors[index] }}>
            <Image style={styles.image} src={page.image} />
            <div className="names">
            <Text style={styles.header} fixed>------------------------------------------------------------------------</Text>
              <Text style={styles.header} fixed>Hello, {currentUser}</Text>
              <Text style={styles.headers} fixed>Average Score - {point}</Text>
              <Text style={styles.headers} fixed>Prize - {prize}</Text>
              <Text style={styles.header} fixed>------------------------------------------------------------------------</Text>

            </div>

            <Text style={styles.text}>
              {page.text}
            </Text>
            <Text
              style={styles.pageNumber}
              render={({ pageNumber, totalPages }) =>
                `${pageNumber} / ${totalPages}`
              }
            />
          </Page>
        )
      })}

    </Document>
  );
};

export default PDFFile;
